<template>
  <div
    :class="'member-page ' + LANGCODE"
    :style="'--aog-currency:\'' + SYSLANG.currency + '\''"
  >
    <div class="header">
      <img class="member-bg" :src="require('@/assets/images/bg-user-center.png')" />
      <div class="base-info">
        <van-image
          round
          width="20vw"
          height="20vw"
          :src="agentInfo.avatar ? agentInfo.avatar : defaultFace"
          fit="cover"
        />
        <div class="info">
          <span class="name">{{ agentInfo.nickName }}</span>
          <span class="item">ID: {{ agentInfo.incId }}</span>
          <span class="item" @click="onCopy(agentInfo.inviteCode)"
            >{{ PAGELANG.invitationcode }}: {{ agentInfo.inviteCode }}</span
          >
        </div>
      </div>
    </div>
    <ul class="mem-menu-02">
      <li class="item">
        <a href="#/profile">
          <span>
            {{ PAGELANG.个人资料 }}
          </span>
        </a>
      </li>
	  <li class="item">
	    <a href="#/address">
	      <span>
	        {{ PAGELANG.dizhi }}
	      </span>
	    </a>
	  </li>
	  <li class="item">
	    <a href="#/contract">
	      <span>
	         我的簽約
	      </span>
	    </a>
	  </li>
	  <li class="item">
	    <a href="#/resetPassword">
	      <span>
	        重置密碼
	      </span>
	    </a>
	  </li>
    </ul>

    <div style="height: 120px"></div>

  <!--  <a class="btn-exit" @click="onLogout">{{ PAGELANG.退出 }}</a> -->
  </div>
</template>

<script>
import { getCookie, setCookie } from "../util/index.js";
import {
  showConfirmDialog,
  showLoadingToast,
  showToast,
  closeToast,
} from "vant";
export default {
  data() {
    return {
      balance: 0, // 货款余额
      withdrawal: 0, // 收益金额
      toberecorded: 0, // 待入账收益
      agentInfo: {},//個人基本信息
	  moneyInfo:{},//個人貨款信息
	  StateInfo:{},//個人提現信息
      defaultFace: require("@/assets/images/default_face.png"),
    };
  },
  beforeCreate() {
    if (!getCookie("agentlogintoken")) {
      this.$router.replace({
        path: "/login",
      });
      return;
    }
  },
  created() {
    if (!getCookie("agentlogintoken")) {
      return;
    }

    this.PAGELANG = this.LANG.pages.my;
    this.SYSLANG = this.LANG.system;
    this.LANGCODE = this.langcode;
    this.UPLOADBASE = this.uploadbase;

    showLoadingToast({
      message: this.SYSLANG.loading,
      duration: 0,
      forbidClick: true,
    });
	
    this.axios.get(this.actions.memberBrandStatus).then((response) => {
		 let { code, message, data } = response.data;
		 console.log('data',data)
		 console.log('code',code)
		 if (code == "200") {
			 //用戶待簽約
			 if(data.inviteStatus==1){
				this.$router.replace({
				  path: "/custom/shen",
				});	
			 }
			 //用戶待打款
		     if(data.inviteStatus==2){
				if(data.taskStatus==0){
					this.$router.replace({
					  path: "/credit/recharge",
					});	
				}
				if(data.taskStatus==1){
					//打款成功 待後台確認
					this.$router.replace({
					  path: "/custom/payment",
					});	
					
					/*
				     showNotify({
				     message:"已提交，等待確認",
					 type:'success',
					 onClose: () => {
					   if (code == "200") {
					     this.$router.replace({
					       path: "/",
					     });
					   }
					 },
				   });
				   */
				}
				//審核成功 獲取個人信息
				if(data.taskStatus==2){
					this.axios.get(this.actions.memberInfo).then((response) => {
					  closeToast();
					 let { code, message, data } = response.data;
					  if (code == "200") {
					    this.agentInfo = data;						
					  } else {
					    showToast({
					      message: code == "NOTLOGIN" ? this.SYSLANG[message] : this.GOODSLANG[message],
					      forbidClick: true,
					      onClose: () => {
					        if (code == "NOTLOGIN") {
					          this.$router.replace({
					            path: "/login",
					          });
					        }
					      },
					    });
					  }
					});
				}
				
				
			 }
		 } else {
		   showToast({
		     message: code == "401" ? this.SYSLANG[message] : this.GOODSLANG[message],
		     forbidClick: true,
		     onClose: () => {
		       if (code == "401") {
		         this.$router.replace({
		           path: "/login",
		         });
		       }
		     },
		   });
		 }
	 })
	
	this.axios.get(this.actions.memberMoney).then((response) => {
	  closeToast();
	 let { code, message, data } = response.data;
	  if (code == "200") {
	    this.moneyInfo = data;
	  } else {
	    showToast({
	      message: code == "NOTLOGIN" ? this.SYSLANG[message] : this.GOODSLANG[message],
	      forbidClick: true,
	      onClose: () => {
	        if (code == "NOTLOGIN") {
	          this.$router.replace({
	            path: "/login",
	          });
	        }
	      },
	    });
	  }
	});
	
	
	this.axios.get(this.actions.memberState).then((response) => {
	  closeToast();
	 let { code, message, data } = response.data;
	  if (code == "200") {
	    this.StateInfo = data;
	  } else {
	    showToast({
	      message: code == "NOTLOGIN" ? this.SYSLANG[message] : this.GOODSLANG[message],
	      forbidClick: true,
	      onClose: () => {
	        if (code == "NOTLOGIN") {
	          this.$router.replace({
	            path: "/login",
	          });
	        }
	      },
	    });
	  }
	});
	
	
	
	
  },
  methods: {
	  
	formatPrice(price){
	      return (price / 100).toFixed(2)
	  },
    onCopy(content) {
      let oInput = document.createElement("input");
      oInput.value = content;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      console.log(oInput.value);
      document.execCommand("Copy"); // 执行浏览器复制命令
      oInput.remove();
      showToast(this.SYSLANG.copysuccess);
    },
    onLogout() {
      showConfirmDialog({
        title: this.SYSLANG.dialogtitle,
        message: this.PAGELANG.您确定退出登录吗,
        confirmButtonText: this.SYSLANG.queding,
        cancelButtonText: this.SYSLANG.quxiao,
      })
        .then(() => {
          //退出登录
          setCookie("agentloginid", "");
          setCookie("agentlogintoken", "");
          this.$router.push("/login");
        })
        .catch(() => {});
    },
    viewPDF() {
      /*this.pagedata.pdfurl = this.UPLOADBASE + this.agentInfo.CertUrl;
      this.$router.push({
        path: "/viewpdf",
      });*/
      window.location.href = this.UPLOADBASE + this.agentInfo.CertUrl;
    },
  },
};
</script>


<style src="../assets/css/my.css" scoped>
</style>